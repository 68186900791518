import React, { FC, useCallback, useEffect, useState } from 'react';
import Modal from 'molecules/Modal';
import {
  CloudOpsProjectsType,
  QuickbooksProjectType,
} from 'pages/CompanyPage/types';
import {
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalSubmitButton,
} from 'molecules/Modal/Modal.styles';
import { Alert, Stack } from '@mui/material';
import { QuickbooksProjectAutoComplete } from 'molecules/ProjectAutoComplete/QuickbooksProjectAutoComplete';
import { ZStreamProjectAutoComplete } from 'molecules/ProjectAutoComplete/ZStreamProjectAutoComplete';
import axios from 'axios';

const QuickBookProjectSyncDialog: FC<{
  project: CloudOpsProjectsType;
  companyHsId?: string;
  onSync: () => void;
  onClose: () => void;
  onAfterClose?: () => void;
}> = ({ companyHsId, onClose, onAfterClose, onSync, project }) => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [alert, setAlert] = useState<{ error: boolean; message: string }>({
    error: false,
    message: '',
  });

  const [quickbooksProjectSelected, setQuickbooksProjectSelected] =
    useState<QuickbooksProjectType>();

  const onSubmit = async () => {
    setSubmitted(true);
    if (!project || !quickbooksProjectSelected) return;

    handleSyncProjects();
  };

  const handleQuickbooksProjectChange = useCallback(
    (selected: QuickbooksProjectType) => {
      setSubmitted(false);
      setQuickbooksProjectSelected(selected);
    },
    []
  );

  const onEmptyAction = () => {
    // eslint-disable-next-line no-console
    console.log('This method will never reached!');
  };

  async function handleSyncProjects() {
    try {
      // If we need to sync Z-Stream project with Quickbooks
      if (project.id) {
        await axios.put(
          `${process.env.REACT_APP_USER_SERVICE}/projects/${project?.id_str}`,
          {
            ...project,
            ...quickbooksProjectSelected,
          }
        );
        if (typeof onSync === 'function') onSync();
      }
    } catch (error) {
      setAlert({ error: true, message: error.response.data.message });
      setTimeout(() => {
        setAlert({ error: false, message: '' });
      }, 3000);
    } finally {
      const closeMethod = onAfterClose || onClose;
      closeMethod();
    }
  }

  useEffect(() => {
    const {
      quickbook_project_name,
      quickbook_project_id,
      parent_company_quickbook_id,
    } = project;

    if (
      !quickbook_project_id ||
      !quickbook_project_name ||
      !parent_company_quickbook_id
    )
      return;

    setQuickbooksProjectSelected({
      quickbook_project_name,
      quickbook_project_id,
      parent_company_quickbook_id,
    });
  }, [project]);

  return (
    <Modal
      open
      maxWidth="sm"
      padding24
      title="Sync with QuickBooks project"
      subtitle="QuickBooks projects automatically detected by Zazmic-Connect can be synced with manually added CloudOps projects that have not yet been synced with a QB project, ensuring full integration."
      onClose={onClose}
      transitionDuration={0}
    >
      <>
        <ModalContent sx={{ overflowY: 'hidden' }}>
          {alert.message && (
            <Alert
              sx={{ width: 'auto !important', minWidth: 'auto !important' }}
              severity={alert.error ? 'error' : 'success'}
            >
              {alert.message}
            </Alert>
          )}
          <Stack gap="22px" pt="5px">
            <ZStreamProjectAutoComplete
              disabled
              project={project}
              submitted={submitted}
              onChange={onEmptyAction}
            />
            <QuickbooksProjectAutoComplete
              companyHsId={String(companyHsId)}
              submitted={submitted}
              project={project as QuickbooksProjectType}
              disabled={false}
              onChange={handleQuickbooksProjectChange}
            />
          </Stack>
        </ModalContent>
        <ModalActions>
          <ModalCloseButton autoFocus variant="outlined" onClick={onClose}>
            Cancel
          </ModalCloseButton>
          <ModalSubmitButton variant="contained" onClick={onSubmit}>
            Sync
          </ModalSubmitButton>
        </ModalActions>
      </>
    </Modal>
  );
};

export default QuickBookProjectSyncDialog;
