import React, { useEffect, useState, useCallback, FC } from 'react';
import axios from 'axios';
import FlexCenterBetween from 'utils/styles/FlexCenterBetween';
import {
  exitPayment,
  setPayInvoice,
  setLoading as setPayLoading,
} from 'store/payInvoice/payInvoiceSlice';
import { Alert, Box, Grid, Typography, Modal as MuiModal } from '@mui/material';
import { Data } from 'organisms/InvoicesTable/types';
import { useBaoDispatch, useBaoSelector } from 'utils/hooks/redux';
import AddPaymentMethodDialog from 'organisms/AddPaymentMethodDialog/AddPaymentMethodDialog';
import { AlertContainer, AlertContainer2 } from 'utils/styles/AlertWrapper';
import PaymentOptions from 'molecules/PaymentOptionRadio/PaymentOptions';
import { fetchPaymentMethods } from 'molecules/PaymentOptionRadio/helpers';
import { currencyFormatter } from 'utils/helpers/currencyFormatter';
import { CaptchaModal } from 'molecules/CaptchaModal/CaptchaModal';
import {
  PaymentFinishedInfoBlock,
  PaymentInfoGrid,
  PaymentInfoTypography,
} from 'molecules/InvoicePopupPaymentOptions/InvoicePopupPaymentOptions.styles';
import {
  ModalContent,
  ModalActionsFullWidth,
  ModalCloseButton,
  ModalSubmitButton,
  ModalActions,
} from 'molecules/Modal/Modal.styles';
import Modal from 'molecules/Modal';
import loaderGif from 'assets/images/loaderGif.gif';
import { CustomModal } from 'pages/DashboardPage/DashboardPage.styles';
import PaymentMethod from 'molecules/PaymentMethod/PaymentMethod';

type ApiResponse = {
  value: string;
  error: boolean;
  errorMessage?: string;
};

const InvoicePopupPaymentOptions: FC<{ onAfterClose: () => void }> = ({
  onAfterClose,
}) => {
  const dispatch = useBaoDispatch();
  const payInvoice = useBaoSelector((state) => state.payInvoice);
  const { currentCompany } = useBaoSelector((state) => state.common);

  const [loading, setLoading] = useState(false);
  const [isPaymentFinished, setIsPaymentFinished] = useState<boolean>(false);
  const [update, setUpdate] = useState(1);
  const [addingPaymentMethod, setAddingPaymentMethod] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<PMethod[]>([]);
  const [prevPayment, setPrevPayment] = useState<PMethod>();
  const [paymentMethodsCopy, setPaymentMethodsCopy] = useState<PMethod[]>([]);
  const [feeEnabled, setFeeEnabled] = useState<boolean>(true);
  const [diference, showDifference] = useState<boolean>(false);
  const [canceled, setCanceled] = useState<boolean>(false);
  const [apiResponse, setApiResponse] = useState<ApiResponse>({
    value: '',
    error: false,
    errorMessage: '',
  });
  const [mainPaymentMethodData, setMainPaymentMethod] = useState<PMethod>();
  const [newPrice, setNewPrice] = useState<boolean | number | string>(false);
  const [showCaptcha, setShowCaptcha] = useState<boolean>(false);

  useEffect(() => {
    if (apiResponse.error) {
      setTimeout(() => {
        setApiResponse({
          value: '',
          error: false,
        });
      }, 10000);
    }
  }, [apiResponse]);

  useEffect(() => {
    (async () => {
      if (update === 1) {
        dispatch(setPayLoading(true));
      }

      const response = await fetchPaymentMethods({
        invoiceId: payInvoice?.invoice.invoiceQbNumber,
        currentCompany,
      });
      if (update === 1) {
        const main = response.find((i: PMethod) => i.isMain);
        if (main) setPrevPayment(main);
        setPaymentMethods(response);
      }

      if (response.length < 1) {
        setApiResponse({ value: 'Please add payment method.', error: true });
      }

      if (update === 1) {
        dispatch(setPayLoading(false));
      }
    })();
  }, [dispatch, update, currentCompany]);

  useEffect(() => {
    const main = paymentMethods.find((i) => i.isMain === true);
    setMainPaymentMethod(main);
    if (mainPaymentMethodData && !canceled) {
      if (
        main?.paymentMethod === 'card' &&
        mainPaymentMethodData?.paymentMethod !== 'card'
      ) {
        showDifference(true);
      }

      if (
        mainPaymentMethodData?.paymentMethod === 'card' &&
        main?.paymentMethod !== 'card'
      ) {
        showDifference(true);
      }

      if (
        mainPaymentMethodData?.paymentMethod === 'ach_debit' &&
        main?.paymentMethod === 'acss_debit'
      ) {
        showDifference(true);
      }

      if (
        mainPaymentMethodData?.paymentMethod === 'acss_debit' &&
        main?.paymentMethod === 'ach_debit'
      ) {
        showDifference(true);
      }
    }
    setCanceled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethods]);

  useEffect(() => {
    (async () => {
      dispatch(setPayLoading(true));

      await dispatch(setPayInvoice(payInvoice.invoice));
      await axios
        .get(
          `${process.env.REACT_APP_BILLING_SERVICE}/payment/credit-card-fee-settings`
        )
        .then((res) => {
          setFeeEnabled(res.data.credit_card_fee_enabled);
        });

      dispatch(setPayLoading(false));
    })();
    return () => setIsPaymentFinished(false);
  }, []);

  const handlePayInvoice = async (invoice: Data[0]) => {
    setShowCaptcha(false);

    // if payment already finished or we are loading data just do nothing
    if (isPaymentFinished || loading) return;

    localStorage.setItem('pay', invoice.id);
    const dataOfPayments =
      paymentMethodsCopy.length > 0 ? paymentMethodsCopy : paymentMethods;
    const mainPayment = dataOfPayments.find((p) => p.isMain === true);

    if (!mainPayment?.isVerified) {
      setApiResponse({
        value: 'Bank verification required before payment can be made',
        error: true,
      });
    } else if (paymentMethods.length === 0) {
      setApiResponse({ value: 'Please add payment method.', error: true });
    } else {
      setLoading(true);
      try {
        await axios.post(
          `${process.env.REACT_APP_BILLING_SERVICE}/payment/pay`,
          {
            invoice_id: invoice.id,
            payment_method: mainPayment?.paymentMethod,
            payment_method_id: mainPayment?.id,
            cancel_url: `${window.location.origin}/dashboard?payment=cancelled`,
            success_url: `${window.location.origin}/dashboard?payment=succeeded`,
            company_hs_id: currentCompany?.company_hs_id,
          }
        );
        setLoading(false);
        setIsPaymentFinished(true);
        // if (response && response.data) {
        //   setLoading(false);
        //   window.location.href = `${window.location.href}?payment=succeeded`;
        // }
        // window.open(response.data.url, '_self');
      } catch (err) {
        setApiResponse({
          value: err.response.data.message,
          errorMessage: err.response.data.error,
          error: true,
        });
        setLoading(false);
      }
    }
  };

  const hideModal = useCallback(() => {
    setShowCaptcha(false);
  }, []);

  const cancel = () => {
    showDifference(false);
    const oldPaymentMethods = paymentMethods.map((i) => {
      i.isMain = prevPayment?.id === i.id;
      return i;
    });
    setPaymentMethods(oldPaymentMethods);
    setCanceled(true);
  };

  const mainPaymentMethod = paymentMethods.find((i) => i.isMain === true);
  const totalAmountDue = (() => {
    if (!mainPaymentMethod) return payInvoice.invoice.amountDue;

    return (
      Number(payInvoice.invoice.amountDue) + Number(mainPaymentMethod.fee || 0)
    );
  })();

  return (
    <>
      <ModalContent>
        <Box
          sx={{
            // mb: '16px',
            alignItems: 'center ',
            '.MuiPaper-root': {
              minWidth: 'auto !important',
              width: 'auto !important',
            },
          }}
        >
          {apiResponse.value && (
            <AlertContainer>
              <Alert
                icon={false}
                severity={apiResponse.error ? 'error' : 'success'}
              >
                {apiResponse.value === 'Please add payment method.'
                  ? ''
                  : 'Payment has failed. Reason:'}{' '}
                {apiResponse.value}
              </Alert>
            </AlertContainer>
          )}
          {payInvoice.loading && (
            <Box sx={{ textAlign: 'center', pb: 2 }}>
              <img src={loaderGif} alt="loader" />
            </Box>
          )}
        </Box>

        {!isPaymentFinished ? (
          <PaymentOptions
            oneTime
            diference={diference}
            updatePrice={(e) => setNewPrice(e)}
            setPaymentToPay={(data) => {
              setPaymentMethods(data);
              setPaymentMethodsCopy(data);
            }}
            onPaymentChange={() => setUpdate(update + 1)}
            dialogOpened={addingPaymentMethod}
            onDialogOpen={() => {
              localStorage.setItem('pay', payInvoice.invoice.id);
              setAddingPaymentMethod(true);
            }}
          />
        ) : (
          <PaymentFinishedInfoBlock>
            <PaymentMethod
              hideExpireDate
              method={mainPaymentMethod as PMethod}
            />
            <AlertContainer2>
              <Alert icon={false} severity="success">
                Payment submitted successfully
              </Alert>
            </AlertContainer2>
          </PaymentFinishedInfoBlock>
        )}

        {/* https://zira.zstream.io/app/tasks/task/RESELL-1820 */}
        {!payInvoice.loading && (
          <FlexCenterBetween style={{ width: '100%', justifyContent: 'end' }}>
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              textAlign="right"
            >
              {feeEnabled && Number(mainPaymentMethod?.fee) > 0 ? (
                <PaymentInfoGrid container rowSpacing={2} columnSpacing={0}>
                  <Grid item xs={9} pr={2}>
                    <PaymentInfoTypography>Amount due</PaymentInfoTypography>
                  </Grid>
                  <Grid item xs={3}>
                    <PaymentInfoTypography>
                      {currencyFormatter(
                        Number(payInvoice.invoice.amountDue),
                        payInvoice.invoice.currency
                      )}
                    </PaymentInfoTypography>
                  </Grid>
                  <Grid item xs={9} pr={2}>
                    <PaymentInfoTypography>
                      Stripe payment processing fee
                    </PaymentInfoTypography>
                  </Grid>
                  <Grid item xs={3}>
                    <PaymentInfoTypography>
                      {currencyFormatter(
                        Number(mainPaymentMethod?.fee),
                        payInvoice.invoice.currency
                      )}
                    </PaymentInfoTypography>
                  </Grid>
                  <Grid item xs={9} pr={2}>
                    <PaymentInfoTypography variant="body2">
                      Amount Due + Fee
                    </PaymentInfoTypography>
                  </Grid>
                  <Grid item xs={3}>
                    <PaymentInfoTypography
                      variant="body2"
                      sx={{ lineHeight: '22px' }}
                    >
                      {feeEnabled
                        ? currencyFormatter(
                            Number(totalAmountDue),
                            payInvoice.invoice.currency
                          )
                        : currencyFormatter(
                            Number(newPrice || payInvoice.invoice.amountDue),
                            payInvoice.invoice.currency
                          )}
                    </PaymentInfoTypography>
                  </Grid>
                </PaymentInfoGrid>
              ) : (
                <PaymentInfoGrid container rowSpacing={2} columnSpacing={0}>
                  <Grid item xs={9} height={34} />
                  <Grid item xs={3} height={34} />
                  <Grid item xs={9} height={34} />
                  <Grid item xs={3} height={34} />
                  <Grid item xs={9} pr={2}>
                    <PaymentInfoTypography variant="body2">
                      Amount Due
                    </PaymentInfoTypography>
                  </Grid>
                  <Grid item xs={3}>
                    <PaymentInfoTypography variant="body2">
                      {feeEnabled
                        ? currencyFormatter(
                            Number(totalAmountDue),
                            payInvoice.invoice.currency
                          )
                        : currencyFormatter(
                            Number(newPrice || payInvoice.invoice.amountDue),
                            payInvoice.invoice.currency
                          )}
                    </PaymentInfoTypography>
                  </Grid>
                </PaymentInfoGrid>
              )}
            </Box>
          </FlexCenterBetween>
        )}
      </ModalContent>

      {!payInvoice.loading && (
        <ModalActionsFullWidth disableSpacing>
          {paymentMethods.length !== 0 && !isPaymentFinished && (
            <ModalSubmitButton
              fullWidth
              variant="contained"
              onClick={() => setShowCaptcha(true)}
            >
              pay
            </ModalSubmitButton>
          )}
          <ModalCloseButton
            fullWidth
            variant="outlined"
            onClick={() => {
              localStorage.removeItem('pay');
              dispatch(exitPayment());
              onAfterClose();
            }}
          >
            close
          </ModalCloseButton>
        </ModalActionsFullWidth>
      )}

      <AddPaymentMethodDialog
        addingPaymentMethod={addingPaymentMethod}
        onAddingPaymentMethod={() => {
          localStorage.removeItem('pay');
          setAddingPaymentMethod(false);
        }}
      />

      {/* difference */}
      <Modal
        open={diference}
        maxWidth="sm"
        title="Change payment method"
        subtitle="Payment method will be changed only for current payment session."
        onClose={cancel}
        sx={{ zIndex: 32000 }}
      >
        <ModalActions>
          <ModalCloseButton
            autoFocus
            size="medium"
            variant="outlined"
            onClick={() => cancel()}
          >
            Cancel
          </ModalCloseButton>
          <ModalSubmitButton
            size="medium"
            variant="contained"
            onClick={() => {
              showDifference(false);
              setPrevPayment(mainPaymentMethodData);
            }}
          >
            Confirm
          </ModalSubmitButton>
        </ModalActions>
      </Modal>

      {showCaptcha ? (
        <CaptchaModal
          onComplete={() => handlePayInvoice(payInvoice.invoice)}
          onClose={hideModal}
        />
      ) : null}

      <MuiModal
        open={loading}
        onClose={() => setLoading(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: 31323 }}
      >
        <CustomModal>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <img src={loaderGif} alt="loader" style={{ width: '20px' }} />
            <Typography id="modal-modal-description">
              Payment is in progress
            </Typography>
          </Box>
        </CustomModal>
      </MuiModal>
    </>
  );
};

export default InvoicePopupPaymentOptions;
