import styled from 'styled-components';
import { CardContent } from '@mui/material';
import { neutrals } from 'utils/styles/color';

export const CustomModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  outline: none;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
`;

export const StyledNotificationContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  gap: 0;
  max-height: 200px;
  overflow-y: auto;
  padding: 0 !important;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #cbcbcb;
    outline: 2px solid #fff;
    outline-offset: -2px;
    border: 0.1px solid #b7b7b7;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #909090;
  }
`;

export const StyledNotificationItem = styled.div`
  && {
    display: flex;
    align-items: center;
    padding: 4px 8px !important;
    gap: 16px;

    border-bottom: 1px solid ${neutrals[100]};
    background: #fff;
    span {
      display: block;
      width: 80px;
      min-width: 80px;

      color: ${neutrals[700]};
      font-family: Poppins, sans-serif;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 300;
      line-height: 15.5px;
    }
    p {
      margin: 0;

      color: ${neutrals[700]};
      font-family: Poppins, sans-serif;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
`;
