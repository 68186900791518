import React, { useState, useCallback, useEffect } from 'react';

import { HeadlessPageWrapper } from 'templates/PageWrapper/PageWrapper';
import { FilterAutocomplete } from 'atoms/TableFilter';
import { StyledPaper } from 'pages/ContactsPage/Contacts.styles';
import { StyledFilterWrapper } from 'molecules/Filter/Filter.styles';

import axios, { AxiosResponse } from 'axios';
import { camelCase, debounce, mapKeys } from 'lodash-es';
import ProjectsTable from 'organisms/ProjectsTable';
import {
  CloudOpsProjectsType,
  QuickbooksProjectType,
} from 'pages/CompanyPage/types';
import {
  prepareProjectStructure,
  projectInitialData,
} from 'pages/CompanyPage/helpers';
import CloudOpsProjectDetailsDialog from 'organisms/ProjectDialogs/CloudOpsProjectDetailsDialog';
import CloudOpsProjectSyncDialog from 'organisms/ProjectDialogs/CloudOpsProjectSyncDialog';
import ZohoProjectSyncDialog from 'organisms/ProjectDialogs/ZohoProjectSyncDialog';
import { currencyIcon } from 'utils/constants/invoiceStatus';
import { Company } from 'pages/CloudOpsReport/types';

const ProjectsPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string | undefined>('');
  const [data, setData] = useState<{
    projects: CloudOpsProjectsType[];
    count: number;
  }>({ projects: [], count: 0 });

  const [company, setCompany] = useState<Company | undefined>();
  const [projectInfo, setProjectInfo] =
    useState<CloudOpsProjectsType>(projectInitialData);
  const [showSyncDialog, setShowSyncDialog] = useState<boolean>(false);
  const [showQuickbookSyncDialog, setShowQuickbookSyncDialog] =
    useState<boolean>(false);
  const [showDetailsDialog, setShowDetailsDialog] = useState<boolean>(false);

  /** debounce handler for two types: textfield and autocomplete */
  const debouncedChangeHandler = useCallback(
    debounce((event, value) => {
      setSearch(value || event?.target?.value || '');
    }, 1000),
    []
  );

  const fetchPageData = async (
    pagination: { page: number; limit: number },
    sort?: string
  ) => {
    try {
      setLoading(true);
      const { projects, count } = await fetchProjectsList(pagination, sort);

      setData({ projects: projects || [], count: count || 0 });
      return { projects: projects || [], count: count || 0 };
    } finally {
      setLoading(false);
    }
  };

  async function fetchProjectsList(
    pagination: { page: number; limit: number },
    sort?: string
  ) {
    const response: AxiosResponse<{
      projects: CloudOpsProjectsType[];
      count: number;
    }> = await axios.get(
      `${process.env.REACT_APP_USER_SERVICE}/projects?${
        sort || 'sort[created_at]=desc'
      }`,
      {
        params: {
          search: search || '',
          page: pagination.page || 1,
          limit: pagination.limit || 20,
        },
      }
    );

    const prjs: CloudOpsProjectsType[] = response.data.projects.map(
      prepareProjectStructure
    ) as CloudOpsProjectsType[];

    return { projects: prjs || [], count: response.data.count || 0 };
  }

  const handleSettingsClick = useCallback(async (row: CloudOpsProjectsType) => {
    const companyResponse: AxiosResponse<Company> = await axios.get(`
        ${process.env.REACT_APP_USER_SERVICE}/company/${row.company_hs_id}
      `);
    setCompany(
      mapKeys(companyResponse.data, (v, k) => camelCase(k)) as Company
    );

    setProjectInfo(row as unknown as CloudOpsProjectsType);

    if (!row.id && row.quickbook_project_id) {
      setShowQuickbookSyncDialog(true);
    } else if (!row.id) {
      setShowSyncDialog(true);
    } else {
      setShowDetailsDialog(true);
    }
  }, []);

  const handleDetailsClose = useCallback(() => {
    setProjectInfo(projectInitialData); // clear selected project details
    setShowDetailsDialog(false);
  }, []);

  const handleUpdateModal = async () => {
    await fetchPageData({ page: 1, limit: 20 });

    const { data: updatedProject }: AxiosResponse<CloudOpsProjectsType> =
      await axios.get(
        `${process.env.REACT_APP_USER_SERVICE}/projects/${projectInfo.id_str}`
      );

    if (updatedProject) {
      setProjectInfo(updatedProject as unknown as CloudOpsProjectsType);
    }
  };

  const handleCancelSyncDialog = useCallback(() => {
    setShowSyncDialog(false);
  }, []);

  const handleSubmitSyncDialog = useCallback(async () => {
    const { projects }: { projects: CloudOpsProjectsType[] } =
      await fetchPageData({ page: 1, limit: 20 });

    if (!projects.length) {
      setShowSyncDialog(false);
      return;
    }

    const { quickbook_project_id } = projectInfo;
    const prj = projects.find(
      (item) => item.quickbook_project_id === quickbook_project_id
    );

    if (!prj) {
      setShowSyncDialog(false);
      return;
    }

    const projectResponse = await axios.get(
      `${process.env.REACT_APP_USER_SERVICE}/projects/${prj.id}`
    );

    setProjectInfo(projectResponse.data);

    setShowSyncDialog(false);
    setShowQuickbookSyncDialog(false);

    setShowDetailsDialog(true);
  }, [projectInfo]);

  useEffect(() => {
    fetchPageData({ page: 1, limit: 20 });
  }, [search]);

  return (
    <>
      <HeadlessPageWrapper>
        <StyledPaper>
          <StyledFilterWrapper>
            <FilterAutocomplete
              isFreeSolo={!!search}
              value={search}
              options={[]}
              placeholder="Search by: Project name, Company name"
              tooltip="Search by: Project name, Company name"
              onChange={debouncedChangeHandler}
              onReset={() => setSearch('')}
            />
          </StyledFilterWrapper>
          <ProjectsTable
            loading={loading}
            data={data}
            onChange={fetchPageData}
            onSettings={handleSettingsClick}
          />
        </StyledPaper>
      </HeadlessPageWrapper>

      {/* Project details dialog */}
      {showDetailsDialog && (
        <CloudOpsProjectDetailsDialog
          currency={company?.currency as keyof typeof currencyIcon}
          project={projectInfo}
          companyHsId={company?.companyHsId}
          onClose={handleDetailsClose}
          onUpdate={handleUpdateModal}
        />
      )}

      {/* Sync project dialog */}
      {showSyncDialog && (
        <CloudOpsProjectSyncDialog
          currency={company?.currency as keyof typeof currencyIcon}
          project={projectInfo as QuickbooksProjectType}
          companyHsId={company?.companyHsId}
          onClose={handleCancelSyncDialog}
          onSync={handleSubmitSyncDialog}
        />
      )}

      {/* Quickbook project sync dialog 1 */}
      {showQuickbookSyncDialog && (
        <ZohoProjectSyncDialog
          project={projectInfo}
          companyHsId={company?.companyHsId}
          onClose={() => setShowQuickbookSyncDialog(false)}
          onSync={handleSubmitSyncDialog}
        />
      )}
    </>
  );
};

export default ProjectsPage;
