import React, { Dispatch, SetStateAction } from 'react';

import { exitPayment } from 'store/payInvoice/payInvoiceSlice';
import { useBaoDispatch, useBaoSelector } from 'utils/hooks/redux';

import Modal from 'molecules/Modal';
import InvoicePopupPaymentOptions from 'molecules/InvoicePopupPaymentOptions/InvoicePopupPaymentOptions';
import InvoicePopupPaymentSteps from 'molecules/InvoicePopupPaymentSteps/InvoicePopupPaymentSteps';

const PayInvoicePopup = ({
  onAfterClose,
  // todo remove this line and onError and handle it properly
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onError,
}: {
  onAfterClose: () => void;
  onError: Dispatch<SetStateAction<string>>;
}) => {
  const dispatch = useBaoDispatch();
  const payInvoice = useBaoSelector((state) => state.payInvoice);

  const handleClose = () => {
    localStorage.removeItem('pay');
    dispatch(exitPayment());
    onAfterClose();
  };

  return (
    <Modal
      fullWidth
      maxWidth="md"
      open={payInvoice.open}
      title={
        payInvoice.step === 0
          ? 'Choose payment method for invoice'
          : 'Collect a Bank Details'
      }
      subtitle={`#${payInvoice.invoice.invoiceQbNumber}`}
      onClose={handleClose}
      sx={{ zIndex: 31322 }}
    >
      {payInvoice.step === 0 ? (
        <InvoicePopupPaymentOptions onAfterClose={onAfterClose} />
      ) : (
        <InvoicePopupPaymentSteps />
      )}
    </Modal>
  );
};

export default PayInvoicePopup;
