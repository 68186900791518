import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import {
  exitPayment,
  setInvoice as setReduxInvoice,
} from 'store/payInvoice/payInvoiceSlice';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Data, Row } from 'organisms/InvoicesTable/types';
import { useBaoDispatch, useBaoSelector } from 'utils/hooks/redux';
import AddPaymentMethodDialog from 'organisms/AddPaymentMethodDialog/AddPaymentMethodDialog';
import { AlertContainer2 } from 'utils/styles/AlertWrapper';
import PaymentOptionRadio from 'molecules/PaymentOptionRadio/PaymentOptionRadio';
import {
  fetchPaymentMethods,
  getCanadianExchangeRate,
} from 'molecules/PaymentOptionRadio/helpers';
import { currency, currencyFormatter } from 'utils/helpers/currencyFormatter';
import {
  CancelButtonWrapper,
  ConfirmButtonWrapper,
} from 'molecules/PaymentOptionRadio/PaymentOptionRadio.style';
import { CaptchaModal } from 'molecules/CaptchaModal/CaptchaModal';
import { useWindowDimensions } from 'pages/AuthPage/AuthPage';
import {
  StyledActionStack,
  StyledGridItem,
  StyledInvoiceDetailsTitle,
  StyledPayButton,
  StyledTitle,
} from 'pages/InvoiceDetailsPage/InvoiceDetailsPage.styles';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-left.svg';
import { MobileInvoicesTitle } from 'utils/styles/Typography.styles';
import { ReactComponent as PdfIcon } from 'assets/icons/pdf.svg';
import { MobilePageWrapper } from 'templates/PageWrapper/PageWrapper';
import { handleDownloadInvoice } from 'organisms/InvoicesTable/helpers';
import { camelCase, mapKeys } from 'lodash-es';
import loaderGif from 'assets/images/loaderGif.gif';
import { MobilePaymentInProgress } from 'pages/InvoicePaymentPage/InvoicePaymentPage.styles';
import PaymentMethod from 'molecules/PaymentMethod/PaymentMethod';

type ApiResponse = {
  value: string;
  error: boolean;
  errorMessage?: string;
};

const MobileInvoicePaymentPage = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const { invoiceId } = useParams() as { invoiceId: string };

  const dispatch = useBaoDispatch();
  const payInvoice = useBaoSelector((state) => state.payInvoice);
  const { currentCompany } = useBaoSelector((state) => state.common);

  const [loading, setLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState<
    'success' | 'error' | null
  >(null);
  const [downloading, setDownloading] = useState(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [update, setUpdate] = useState(1);
  const [addingPaymentMethod, setAddingPaymentMethod] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<PMethod[]>([]);
  const [canadianAmount, setCanadianAmount] = useState<{
    amountDue: number | null;
    processingFee: number | null;
  }>({
    amountDue: null,
    processingFee: null,
  });
  const [paymentSelectedIsPAD, setPaymentSelectedIsPAD] =
    useState<boolean>(false);
  const [prevPayment, setPrevPayment] = useState<PMethod>();
  const [paymentMethodsCopy, setPaymentMethodsCopy] = useState<PMethod[]>([]);
  const [feeEnabled, setFeeEnabled] = useState<boolean>(true);
  const [diference, showDifference] = useState<boolean>(false);
  const [canceled, setCanceled] = useState<boolean>(false);
  const [apiResponse, setApiResponse] = useState<ApiResponse>({
    value: '',
    error: false,
    errorMessage: '',
  });
  const [mainPaymentMethodData, setMainPaymentMethod] = useState<PMethod>();
  const [newPrice, setNewPrice] = useState<boolean | number | string>(false);
  const [showCaptcha, setShowCaptcha] = useState<boolean>(false);

  const handleGoBack = useCallback(() => {
    navigate(`/invoice-details/${invoiceId}`);
  }, [navigate, invoiceId]);

  const handlePayInvoice = async (invoice: Data[0]) => {
    setShowCaptcha(false);

    // if payment already finished or we are loading data just do nothing
    if (paymentStatus || loading) return;

    localStorage.setItem('pay', invoice.id);
    const dataOfPayments =
      paymentMethodsCopy.length > 0 ? paymentMethodsCopy : paymentMethods;
    const mainPayment = dataOfPayments.find((p) => p.isMain === true);

    if (!mainPayment?.isVerified) {
      setApiResponse({
        value: 'Bank verification required before payment can be made',
        error: true,
      });
    } else if (paymentMethods.length === 0) {
      setApiResponse({ value: 'Please add payment method.', error: true });
    } else {
      setLoading(true);
      try {
        await axios.post(
          `${process.env.REACT_APP_BILLING_SERVICE}/payment/pay`,
          {
            invoice_id: invoice.id,
            payment_method: mainPayment?.paymentMethod,
            payment_method_id: mainPayment?.id,
            cancel_url: `${window.location.origin}/dashboard?payment=cancelled`,
            success_url: `${window.location.origin}/dashboard?payment=succeeded`,
            company_hs_id: currentCompany?.company_hs_id,
          }
        );
        setPaymentStatus('success');
        setLoading(false);
      } catch (err) {
        setApiResponse({
          value: err.response.data.message,
          errorMessage: err.response.data.error,
          error: true,
        });
        setLoading(false);
        setPaymentStatus('error');
      }
    }
  };

  const cancel = () => {
    showDifference(false);
    const oldPaymentMethods = paymentMethods.map((i) => {
      i.isMain = prevPayment?.id === i.id;
      return i;
    });
    setPaymentMethods(oldPaymentMethods);
    setCanceled(true);
  };

  const hideModal = useCallback(() => {
    setShowCaptcha(false);
  }, []);

  const onDownload = useCallback(async () => {
    if (!payInvoice.invoice || !payInvoice.invoice?.invoiceQbId) return;

    try {
      setDownloading(true);

      await handleDownloadInvoice([payInvoice.invoice.invoiceQbId]);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    } finally {
      setDownloading(false);
    }
  }, [payInvoice.invoice]);

  useEffect(() => {
    fetchPaymentMethods({
      invoiceId: payInvoice?.invoice.invoiceQbNumber,
      currentCompany,
    }).then((response) => {
      const main = response.find((i: PMethod) => i.isMain);
      if (main) {
        setPrevPayment(main);
      }
    });
  }, [currentCompany]);

  // useEffect(() => {
  //   if (apiResponse.error) {
  //     setTimeout(() => {
  //       setApiResponse({
  //         value: '',
  //         error: false,
  //       });
  //     }, 10000);
  //   }
  // }, [apiResponse]);

  useEffect(() => {
    fetchPaymentMethods({
      invoiceId: payInvoice?.invoice.invoiceQbNumber,
      currentCompany,
    }).then((response) => {
      if (update === 1) {
        setPaymentMethods(response);
      }
      if (response.length < 1) {
        setApiResponse({ value: 'Please add payment method.', error: true });
      }
    });
  }, [dispatch, update, currentCompany]);

  useEffect(() => {
    const mainPaymentMethod = paymentMethods.find((i) => i.isMain === true);
    if (mainPaymentMethod) {
      setPaymentSelectedIsPAD(mainPaymentMethod.paymentMethod === 'acss_debit');
      if (mainPaymentMethod.paymentMethod === 'acss_debit') {
        const usdAmountDue = Number(payInvoice.invoice.amountDue);
        const usdProcessingFee = Number(mainPaymentMethod.fee);
        (async () => {
          const { canadianAmount: canadianAmountDueResult } =
            await getCanadianExchangeRate(usdAmountDue);
          const { canadianAmount: canadianProcessingFeeResult } =
            await getCanadianExchangeRate(usdProcessingFee);
          setCanadianAmount({
            amountDue: canadianAmountDueResult,
            processingFee: canadianProcessingFeeResult,
          });
        })();
      }
    }
  }, [paymentMethods, payInvoice]);

  useEffect(() => {
    const main = paymentMethods.find((i) => i.isMain === true);
    setMainPaymentMethod(main);
    if (mainPaymentMethodData && !canceled) {
      if (
        main?.paymentMethod === 'card' &&
        mainPaymentMethodData?.paymentMethod !== 'card'
      ) {
        showDifference(true);
      }

      if (
        mainPaymentMethodData?.paymentMethod === 'card' &&
        main?.paymentMethod !== 'card'
      ) {
        showDifference(true);
      }

      if (
        mainPaymentMethodData?.paymentMethod === 'ach_debit' &&
        main?.paymentMethod === 'acss_debit'
      ) {
        showDifference(true);
      }

      if (
        mainPaymentMethodData?.paymentMethod === 'acss_debit' &&
        main?.paymentMethod === 'ach_debit'
      ) {
        showDifference(true);
      }
    }
    setCanceled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethods]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BILLING_SERVICE}/payment/credit-card-fee-settings`
      )
      .then((res) => {
        setFeeEnabled(res.data.credit_card_fee_enabled);
      });
    return () => setPaymentStatus(null);
  }, []);

  useEffect(() => {
    (async () => {
      if (!invoiceId) return null;

      try {
        setFetching(true);

        const response = await axios.get(
          `${process.env.REACT_APP_BILLING_SERVICE}/invoices/${invoiceId}`
        );

        const camelCasedInvoice = mapKeys(response.data, (v, k) =>
          camelCase(k)
        ) as Row;

        if (camelCasedInvoice.amountDue < 0.5) {
          setApiResponse({
            value: 'Invoice amount is lower then 0.5 usd',
            error: true,
          });
        }

        dispatch(setReduxInvoice(camelCasedInvoice));
      } finally {
        setFetching(false);
      }
      return null;
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId]);

  useEffect(() => {
    if (width >= 600) {
      navigate(-1);
    }
  }, [navigate, width]);

  useEffect(
    () => () => {
      dispatch(exitPayment());
      // eslint-disable-next-line
    },
    []
  );

  const mainPaymentMethod = paymentMethods.find((i) => i.isMain === true);
  const totalAmountDue = (() => {
    if (!mainPaymentMethod) return payInvoice.invoice.amountDue;

    return (
      Number(payInvoice.invoice.amountDue) + Number(mainPaymentMethod.fee || 0)
    );
  })();

  return (
    <MobilePageWrapper>
      <StyledInvoiceDetailsTitle sx={{ pb: '0 !important' }}>
        <StyledTitle direction="row">
          {!loading ? (
            <IconButton onClick={handleGoBack} sx={{ padding: '0' }}>
              <ArrowIcon />
              Back
            </IconButton>
          ) : (
            <span />
          )}
          <MobileInvoicesTitle variant="h2">
            #{payInvoice.invoice.invoiceQbNumber}
          </MobileInvoicesTitle>
        </StyledTitle>
      </StyledInvoiceDetailsTitle>

      {apiResponse.value ? (
        <Box mb={2}>
          <AlertContainer2>
            <Alert
              icon={false}
              severity={paymentStatus === 'success' ? 'success' : 'error'}
            >
              {paymentStatus === 'success'
                ? 'Payment submitted successfully'
                : apiResponse.value}
            </Alert>
          </AlertContainer2>
        </Box>
      ) : (
        <span />
      )}

      <StyledInvoiceDetailsTitle sx={{ pt: '0 !important' }}>
        <Grid container rowSpacing={1} sx={{ minHeight: '80px' }}>
          {paymentMethods.filter(
            (i) => i.isMain && ['card', 'ach_debit'].includes(i.paymentMethod)
          ).length > 0 &&
            feeEnabled && (
              <>
                <StyledGridItem item xs={9}>
                  Invoice Amount due
                </StyledGridItem>
                <StyledGridItem item xs={3}>
                  {currencyFormatter(
                    Number(payInvoice.invoice.amountDue),
                    payInvoice.invoice.currency
                  )}
                </StyledGridItem>
                <StyledGridItem item xs={9} />
                <StyledGridItem item xs={3}>
                  +
                </StyledGridItem>
                <StyledGridItem item xs={9}>
                  Payment Processing Fee
                </StyledGridItem>
                <StyledGridItem item xs={3}>
                  {currencyFormatter(
                    Number(mainPaymentMethod?.fee),
                    payInvoice.invoice.currency
                  )}
                </StyledGridItem>
              </>
            )}

          {paymentSelectedIsPAD && (
            <>
              <StyledGridItem item xs={9}>
                Amount due in CAD
              </StyledGridItem>
              <StyledGridItem item xs={3}>
                {currency.format(canadianAmount.amountDue || 0)}
              </StyledGridItem>
              <StyledGridItem item xs={9} />
              <StyledGridItem item xs={3}>
                +
              </StyledGridItem>
              <StyledGridItem item xs={9}>
                Payment Processing Fee in CAD
              </StyledGridItem>
              <StyledGridItem item xs={3}>
                {currency.format(canadianAmount.processingFee || 0)}
              </StyledGridItem>
            </>
          )}

          <StyledGridItem item xs={9} $bold>
            Amount Due
          </StyledGridItem>
          <StyledGridItem item xs={3} $bold>
            {feeEnabled
              ? currencyFormatter(
                  Number(totalAmountDue),
                  payInvoice.invoice.currency
                )
              : currencyFormatter(
                  Number(newPrice || payInvoice.invoice.amountDue),
                  payInvoice.invoice.currency
                )}
          </StyledGridItem>
        </Grid>

        {!(loading || paymentStatus === 'error') && (
          <StyledActionStack direction="row">
            <LoadingButton
              aria-label="download"
              onClick={onDownload}
              loading={downloading}
            >
              <PdfIcon width={32} height={32} />
            </LoadingButton>
            {!loading && (
              <ConfirmButtonWrapper>
                {paymentMethods.length !== 0 && (
                  <StyledPayButton
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={() => setShowCaptcha(true)}
                  >
                    Pay
                  </StyledPayButton>
                )}
              </ConfirmButtonWrapper>
            )}
          </StyledActionStack>
        )}
      </StyledInvoiceDetailsTitle>

      {(loading || paymentStatus) && mainPaymentMethod ? (
        <Box
          sx={{
            padding: '8px 16px',
            boxShadow: '0px 1px 1px 0px #999',
          }}
        >
          <PaymentMethod hideExpireDate method={mainPaymentMethod as PMethod} />
        </Box>
      ) : (
        <span />
      )}

      {fetching ? (
        <Box sx={{ textAlign: 'center', padding: '40px 0' }}>
          <img src={loaderGif} alt="loader" />
        </Box>
      ) : (
        <Box>
          {loading ? (
            <MobilePaymentInProgress>
              <Typography>Payment in progress</Typography>
            </MobilePaymentInProgress>
          ) : (
            <>
              {!paymentStatus && (
                <Box
                  display="flex"
                  flexDirection="row"
                  marginTop="16px"
                  minHeight="200px"
                >
                  <PaymentOptionRadio
                    oneTime
                    diference={diference}
                    updatePrice={(e) => setNewPrice(e)}
                    setPaymentToPay={(data) => {
                      setPaymentMethods(data);
                      setPaymentMethodsCopy(data);
                    }}
                    onPaymentChange={() => setUpdate(update + 1)}
                    dialogOpened={addingPaymentMethod}
                    onDialogOpen={() => {
                      localStorage.setItem('pay', payInvoice.invoice.id);
                      setAddingPaymentMethod(true);
                    }}
                  />

                  <AddPaymentMethodDialog
                    addingPaymentMethod={addingPaymentMethod}
                    onAddingPaymentMethod={() => {
                      localStorage.removeItem('pay');
                      setAddingPaymentMethod(false);
                    }}
                  />
                </Box>
              )}
            </>
          )}

          {/* difference */}
          <Dialog maxWidth="md" open={diference} sx={{ zIndex: 32000 }}>
            <DialogTitle>Do you want to change payment method?</DialogTitle>
            <DialogContent style={{ minHeight: '40px' }}>
              {mainPaymentMethodData?.paymentMethod === 'card'
                ? 'Card processing fee apply'
                : ''}{' '}
            </DialogContent>
            <DialogActions>
              <CancelButtonWrapper>
                <Button
                  size="medium"
                  autoFocus
                  variant="outlined"
                  style={{
                    borderColor: '#6f9fe9',
                    color: '#6f9fe9',
                  }}
                  onClick={() => cancel()}
                >
                  Cancel
                </Button>
              </CancelButtonWrapper>
              <ConfirmButtonWrapper>
                <Button
                  size="medium"
                  variant="contained"
                  onClick={() => {
                    showDifference(false);
                    setPrevPayment(mainPaymentMethodData);
                  }}
                >
                  Confirm
                </Button>
              </ConfirmButtonWrapper>
            </DialogActions>
          </Dialog>

          {showCaptcha ? (
            <CaptchaModal
              onComplete={() => handlePayInvoice(payInvoice.invoice)}
              onClose={hideModal}
              className={width > 600 ? '' : 'mobile-modal'}
            />
          ) : (
            <span />
          )}
        </Box>
      )}
    </MobilePageWrapper>
  );
};

export default MobileInvoicePaymentPage;
