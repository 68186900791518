import styled from 'styled-components';
import { Stack, Typography } from '@mui/material';
import Circle from 'utils/styles/Circle.styles';
import { neutrals } from 'utils/styles/color';

interface Props {
  height: boolean;
}

export const HeaderTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableWrapper = styled.div<Props>`
  .MuiTableContainer-root {
    max-height: ${(p) => (p.height ? '985px' : '675px')};
  }
`;

export const InvoicesCircle = styled(Circle)`
  background-color: #f5baba;
  color: #ffffff;
  font-size: 0.75rem;
`;

export const StyledOpenInvoicesHeaderBlock = styled(Stack)<{
  $right?: boolean;
}>`
  && {
    display: flex;
    flex-direction: column;
    align-items: start;

    ${(p) => p.$right && 'align-items: end;'}
  }
`;

export const StyledOpenInvoicesHeaderText = styled(Typography)<{
  $pl?: string;
}>`
  && {
    color: ${neutrals[700]};

    font-family: Poppins, sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    ${(p) => p.$pl && `padding-left: ${p.$pl};`}
  }
`;

export const StyledHrLine = styled.hr`
  border: 0.5px solid ${neutrals[200]};
  width: 100%;
  margin: 4px 0;
`;

export const StyledInvoicePaymentItem = styled(Stack)`
  && {
    display: flex;
    flex-direction: row;
    height: 24px;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    > .MuiTypography-root:first-of-type {
      width: 90px;
      text-align: right;
    }
    > .MuiTypography-root:nth-of-type(2) {
      width: 200px;
    }
    .MuiBox-root {
      justify-content: space-between;
      > .MuiTypography-root {
        display: flex;
        align-items: center;
      }
    }
  }
`;
