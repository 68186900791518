import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useBaoDispatch, useBaoSelector } from 'utils/hooks/redux';
import { reSendOtp, setError, verifyOtp } from 'store/user/userSlice';
import { useWindowDimensions } from 'pages/AuthPage/AuthPage';

import { Form } from 'pages/AuthPage/AuthPage.styles';
import Link from 'atoms/Link/Link';
import { palette } from 'utils/styles/variables';
import { formatMS } from 'utils/helpers/dates';

const initialState = { value: '', error: '' };

const VerificationForm = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const dispatch = useBaoDispatch();
  const {
    value: user,
    error: reduxError,
    loading,
  } = useBaoSelector((state) => state.user);

  const timeoutRef = useRef<NodeJS.Timer>();

  const [otp, setOtp] = useState<{ value: string; error: string }>(
    initialState
  );
  const [resendInfo, setResendInfo] = useState<{
    timer: number | null;
    isResent: boolean;
  }>({
    timer: null, // 3 * 60 * 1000,
    isResent: true,
  });
  const [isBlocked, setIsBlocked] = useState<boolean>(false);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      const response = await dispatch(verifyOtp({ otp: otp.value }));

      if (response.type.indexOf('rejected') !== -1) {
        return null;
      }

      const redirectPath = searchParams.get('redirect');

      return navigate(redirectPath || '/dashboard');
    },
    [searchParams, dispatch, otp]
  );

  const handleChange = useCallback((event) => {
    const { value } = event.target;

    if (value && !/^[0-9\b]+$/.test(value)) return;

    if (value.length > 6) return;

    setOtp((prev) => ({ ...prev, value }));
  }, []);

  const handleResendCode = useCallback(async () => {
    await dispatch(reSendOtp());

    timeoutRef.current = setInterval(() => {
      const resendTime = checkResendTime();

      if (resendTime !== 0) {
        setResendInfo((prev) => ({ ...prev, timer: resendTime }));
      } else {
        setResendInfo((prev) => ({ ...prev, isResent: false }));
        localStorage.removeItem('otpExpireAt');
        clearInterval(timeoutRef.current);
      }
    }, 1000);

    setResendInfo({ isResent: true, timer: checkResendTime() });
  }, [dispatch]);

  useEffect(() => {
    if (!user?.credentials) navigate('/signin');
    // eslint-disable-next-line
  }, []);

  useEffect(
    () => {
      clearTimeout(timeoutRef.current);

      if (reduxError) {
        if (reduxError.indexOf('temporary blocked') >= 0) {
          setIsBlocked(true);
          setOtp(initialState);
        } else {
          setOtp((prev) => ({ ...prev, error: reduxError }));
        }
      }

      const blockedTime = checkIfUserBlocked(); // returns ms until blocked time.

      if (blockedTime) {
        setIsBlocked(true);
        timeoutRef.current = setInterval(() => {
          if (!checkIfUserBlocked()) {
            setIsBlocked(false);
            setResendInfo({ isResent: false, timer: 0 });
            clearTimeout(timeoutRef.current);
            localStorage.removeItem('blocked');
          }
        }, 1000);
      } else {
        setIsBlocked(false);
        localStorage.removeItem('blocked');

        if (checkResendTime()) {
          timeoutRef.current = setInterval(() => {
            const resendTime = checkResendTime();

            if (resendTime !== 0) {
              setResendInfo((prev) => ({ ...prev, timer: resendTime }));
            } else {
              setResendInfo((prev) => ({ ...prev, isResent: false }));
              clearInterval(timeoutRef.current);
              localStorage.removeItem('otpExpireAt');
            }
          }, 1000);
        } else {
          setResendInfo({ isResent: false, timer: 0 });
          clearTimeout(timeoutRef.current);
        }
      }

      return () => clearTimeout(timeoutRef.current);
    },
    // eslint-disable-next-line
    [reduxError]
  );

  function checkIfUserBlocked(): number {
    const blockedInfo = JSON.parse(localStorage.getItem('blocked') || '{}');

    if (!blockedInfo.message) return 0;

    const diff = +blockedInfo.timestamp - +new Date() / 1000;

    dispatch(setError(blockedInfo.message));

    return diff > 0 ? diff : 0;
  }

  function checkResendTime(): number {
    const loggedInAt = Number(localStorage.getItem('otpExpireAt') || '0');

    if (!loggedInAt) return 0;

    const diff = loggedInAt - +new Date();

    return diff > 0 ? diff : 0;
  }

  if (width < 600) {
    return (
      <>
        {isBlocked && reduxError ? (
          <Typography
            variant="body2"
            color="#EF4444"
            m="0 0 34px"
            sx={{ fontSize: '1.175rem' }}
          >
            {reduxError}
          </Typography>
        ) : (
          <Typography
            variant="body2"
            color="#31363E"
            m="0 0 34px"
            sx={{ fontSize: '1.175rem' }}
          >
            Your account is protected with two-factor authentication. We’ve sent
            you an email with the code. Please enter the code below.
          </Typography>
        )}
        <Form noValidate onSubmit={handleSubmit} onChange={handleChange}>
          <TextField
            fullWidth
            variant="outlined"
            label="Two-Factor Code"
            margin="normal"
            type="text"
            name="otp"
            value={otp.value}
            inputProps={{ min: 0, style: { textAlign: 'center' } }}
            sx={{ mt: 1, paddingBottom: '10px', height: '40px' }}
            error={!!otp.error}
            helperText={otp.error}
            disabled={isBlocked}
          />
          {resendInfo.isResent && !isBlocked ? (
            <Typography
              fontSize="0.875rem"
              color="#575D66"
              variant="body2"
              m="10px 0 34px"
              sx={{ fontWeight: 400, color: '#575D66', fontSize: '1rem' }}
            >
              {resendInfo.timer && (
                <>
                  You will be able to resend a code again in{' '}
                  {formatMS(resendInfo.timer)}
                </>
              )}
              .
            </Typography>
          ) : (
            <Button
              variant="text"
              type="button"
              sx={{
                textDecorationLine: 'underline',
                fontWeight: 400,
                padding: 0,
                textAlign: 'right',
                fontSize: '1rem',
                margin: '5px 0 34px auto',
                display: 'block',
                ':disabled': {
                  color: '#7B828C',
                },
              }}
              onClick={handleResendCode}
              disabled={isBlocked}
            >
              Resend code
            </Button>
          )}
          <LoadingButton
            variant="contained"
            type="submit"
            sx={{
              margin: '5px 0 20px',
              ':disabled': {
                backgroundColor: '#A1A8B2',
                color: '#FFFFFF',
              },
            }}
            fullWidth
            loading={loading}
            disabled={isBlocked}
          >
            Verify
          </LoadingButton>
          <Typography
            textAlign="center"
            variant="body1"
            fontSize="1rem"
            color="#999999"
            className="form-footer"
          >
            Back to{' '}
            <Link to="/signin" $color={palette.primary.main}>
              Sign in
            </Link>
          </Typography>
        </Form>
      </>
    );
  }

  return (
    <>
      {isBlocked && reduxError ? (
        <Typography
          variant="body2"
          color="#EF4444"
          m="0 0 34px"
          className="mobile-subheader"
        >
          {reduxError}
        </Typography>
      ) : (
        <Typography
          variant="body2"
          color="#31363E"
          m="0 0 34px"
          className="mobile-subheader"
        >
          Your account is protected with two-factor authentication. We’ve sent
          you an email with the code. Please enter the code below or{' '}
          <Link
            $fontWeight={400}
            $fontSize="0.875rem"
            $lineHeight="22px"
            to="/signin"
            $color={palette.primary.main}
          >
            Back to Log in page
          </Link>
          .
        </Typography>
      )}
      <Form noValidate onSubmit={handleSubmit} onChange={handleChange}>
        <TextField
          fullWidth
          variant="outlined"
          label="Two-Factor Code"
          margin="normal"
          type="text"
          name="otp"
          value={otp.value}
          inputProps={{ min: 0, style: { textAlign: 'center' } }}
          sx={{ mt: 1, height: '80px' }}
          error={!!otp.error}
          helperText={otp.error}
          disabled={isBlocked}
        />
        <LoadingButton
          variant="contained"
          type="submit"
          sx={{
            margin: '5px 0 20px',
            ':disabled': {
              backgroundColor: '#A1A8B2',
              color: '#FFFFFF',
            },
          }}
          fullWidth
          loading={loading}
          disabled={isBlocked}
        >
          Continue
        </LoadingButton>
        {resendInfo.isResent && !isBlocked ? (
          <Typography
            fontSize="0.875rem"
            color="#575D66"
            variant="body2"
            // m="10px 0 34px"
            sx={{ fontWeight: 400 }}
          >
            {resendInfo.timer && (
              <>
                You will be able to resend a code again in{' '}
                {formatMS(resendInfo.timer)}.
              </>
            )}
          </Typography>
        ) : (
          <Button
            variant="text"
            type="button"
            sx={{
              textDecorationLine: 'underline',
              fontWeight: 400,
              padding: 0,
              ':disabled': {
                color: '#7B828C',
              },
            }}
            onClick={handleResendCode}
            disabled={isBlocked}
          >
            Resend code
          </Button>
        )}
      </Form>
    </>
  );
};

export default VerificationForm;
