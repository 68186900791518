import styled from 'styled-components';
import { Stack, Typography } from '@mui/material';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & > div:nth-child(2) {
    width: 400px;
    min-height: 240px;
  }

  & > div:nth-child(1) {
    flex: 1;
    margin-right: 16px;
    min-height: 220px;
  }
`;

export const MobilePaymentSettingsContent = styled(Stack)`
  && {
    gap: 10px;

    && > .MuiBox-root:first-of-type {
      display: flex;
      justify-content: space-between;
      padding: 16px;

      p {
        color: var(--neutrals-800, #262b33);
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }
    }
  }
`;

export const MobilePaymentTermsContent = styled(Stack)`
  && {
    padding: 0 16px 24px;
    h5 {
      color: var(--neutrals-800, #262b33);
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
      margin-bottom: 20px;
    }
    p {
      color: var(--greys-gr-dark-25, #45484d);
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      margin: 9px 0;
    }
    .MuiButton-root {
      flex: 1;
      padding: 8px 16px;
      color: var(--primary-blue, #6f9fe9);
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 20px; /* 125% */
    }
  }
`;

export const StyledAutoPaymentText = styled(Typography)`
  && {
    font-family: Poppins, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
`;

export const StyledAutoPaymentLabel = styled(Typography)`
  && {
    font-family: Poppins, sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-align: left;
    text-transform: uppercase;
  }
`;
