import React, { FC, useState } from 'react';
import Modal from 'molecules/Modal';
import { CloudOpsProjectsType } from 'pages/CompanyPage/types';
import {
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalSubmitButton,
} from 'molecules/Modal/Modal.styles';
import axios from 'axios';
import { Alert } from '@mui/material';

const CloudOpsProjectStatusDialog: FC<{
  project: CloudOpsProjectsType;
  onClose: () => void;
  onAfterClose: () => void;
}> = ({ onClose, onAfterClose, project }) => {
  const [alert, setAlert] = useState<{ error: boolean; message: string }>({
    error: false,
    message: '',
  });

  const onSubmit = async () => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_USER_SERVICE}/projects/change-status`,
        {
          project_id: project?.id_str || String(project?.id || 0),
          status: project?.status === 'active' ? 'inactive' : 'active',
        }
      );

      onAfterClose();
    } catch (err) {
      setAlert({ error: true, message: err.response.data.message });
      setTimeout(() => {
        setAlert({ error: false, message: '' });
      }, 3000);
    }
  };

  return (
    <Modal
      open
      maxWidth="sm"
      padding24
      transitionDuration={0}
      title={
        project?.status === 'active'
          ? 'Deactivate the project'
          : 'Activate the project'
      }
      subtitle={
        project?.status === 'active'
          ? 'Project will be deactivated and Zazmic-Connect will stop generating CloudOps monthly reports and will delete report for current month. Previous months reports will stay.'
          : 'If you activate a project during a day Zazmic-Connect will start generate CloudOps monthly reports starting from current month (it will include all logged hours of from the 1st day of the month) and then trigger invoice creation according them once a month. Invoices will be created based on the approved report starting on the 5th day of the following month. For example, for a July report, the invoice will be created from August 5th based on the approved logged time.'
      }
      onClose={onClose}
    >
      <>
        {alert.message ? (
          <ModalContent>
            <Alert
              sx={{ width: 'auto !important', minWidth: 'auto !important' }}
              severity={alert.error ? 'error' : 'success'}
            >
              {alert.message}
            </Alert>
          </ModalContent>
        ) : undefined}
        <ModalActions>
          <ModalCloseButton autoFocus variant="outlined" onClick={onClose}>
            Cancel
          </ModalCloseButton>
          <ModalSubmitButton variant="contained" onClick={onSubmit}>
            Confirm
          </ModalSubmitButton>
        </ModalActions>
      </>
    </Modal>
  );
};

export default CloudOpsProjectStatusDialog;
