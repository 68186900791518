import styled from 'styled-components';
import { breakpoints } from 'utils/styles/variables';
import { Box, IconButton, MenuList, Stack } from '@mui/material';
import { light, neutrals } from 'utils/styles/color';
import Link from 'atoms/Link/Link';

export const StyledHeader = styled('header')`
  background-color: #fff;
  box-shadow: 0 3px 6px #18241512;
  position: relative;
  z-index: 0;

  @media (max-width: 600px) {
    box-shadow: none;
    position: sticky;
    top: 0;
    z-index: 9;
  }
`;

export const StyledHeaderContent = styled(Stack)`
  && {
    display: flex;
    flex-direction: row;
    padding: 16px 0;
    align-items: center;
    gap: 64px;
    align-self: stretch;
  }
`;

export const StyledLogoContainer = styled(Stack)`
  && {
    display: flex;
    height: 44px;
    padding: 2px 0;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 2px;

    a {
      display: flex;
    }
  }
`;

export const StyledLogoText = styled('span')`
  && {
    color: ${neutrals[800]};
    font-family: Montserrat, sans-serif;
    font-size: 1rem;
    font-style: italic;
    font-weight: 600;
    line-height: 20px;
    text-transform: capitalize;
    text-decoration: none;
  }
`;

export const FlexBox = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0 15px;
`;

export const StyledHeaderMenuItem = styled(Link)`
  && {
  }
`;

export const StyledHeaderMenu = styled(Box)<{
  $admin?: boolean;
}>`
  ${({ $admin }) =>
    $admin
      ? {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: '1 0 0',
        }
      : {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          flexGrow: 1,
        }};

  @media (max-width: ${breakpoints.sm}) {
    display: none;
  }

  > .MuiTypography-root.MuiLink-root {
    position: relative;
    cursor: pointer;
    text-decoration: none;
    color: ${neutrals[800]};

    font-family: Poppins, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-transform: capitalize;

    @keyframes in {
      from {
        width: 70%;
        opacity: 0;
      }
      to {
        width: 100%;
        opacity: 1;
      }
    }

    @keyframes out {
      from {
        width: 100%;
        opacity: 1;
      }
      to {
        width: 70%;
        opacity: 0;
      }
    }

    &.active:after {
      content: '';
      width: 100%;
      background: #fc3636;
      position: absolute;
      height: 4px;
      left: 0;
      bottom: -10px;
    }

    :not(.active) {
      :after {
        animation: out 0.3s ease-out;
        content: '';
        background: #47c26a;
        position: absolute;
        height: 4px;
        left: 0;
        bottom: -10px;
      }

      &:hover:after {
        width: 100%;
        animation: in 0.3s ease-out;
      }
    }

    &:not(:first-child) {
      @media (min-width: ${breakpoints.sm}) {
        margin-left: 3.5vw;
      }
      @media (min-width: 1921px) {
        margin-left: 1.5vw;
      }
      @media (max-width: ${breakpoints.sm}) {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: ${breakpoints.sm}) {
    margin-left: auto;
  }
`;

export const StyledMenuList = styled(MenuList)<{
  $isMobile?: boolean;
}>`
  && {
    padding: 0;
    && .MuiMenuItem-root {
      display: flex;
      gap: 8px;

      min-height: auto;
      padding: ${({ $isMobile }) => ($isMobile ? '6px 12px' : '10px 12px')};
      &:hover {
        background-color: ${light[100]};
      }

      & > .MuiTypography-root {
        display: flex;
        gap: 8px;
      }
    }

    && a {
      text-decoration: none;
      display: flex;
      align-items: center;
    }

    && .MuiTypography-root {
      color: ${neutrals[800]};
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 20px;
    }

    && .active > .MuiTypography-root {
      color: #fc3636;
    }

    && .MuiMenuItem-root.Mui-disabled {
      height: 28px;
      padding: 8px 12px 0 12px;
      gap: 8px;
      opacity: 1;

      .MuiTypography-root {
        font-family: Poppins, sans-serif;
        font-size: 0.75rem;
        font-weight: 600;
        line-height: 20px;
      }
    }

    && .MuiListItemIcon-root {
      height: 18px;
      width: 18px;

      min-width: auto;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    && .MuiMenuItem-root > a {
      width: 100%;
      height: 100%;
    }

    @media (min-width: ${breakpoints.sm}) {
      && .MuiTypography-root {
        font-size: 0.875rem;
      }
    }
  }
`;

export const MobileWrapper = styled.div`
  display: none;

  @media (max-width: ${breakpoints.sm}) {
    display: block;

    & > a {
      display: block;
    }
  }
`;

export const StyledIconButton = styled(IconButton)`
  && {
    color: black;
    padding: 0;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    &:hover {
      background: ${light[100]};
    }

    @media (max-width: 600px) {
      margin-right: 0;
      padding: 0;
    }
  }
`;
