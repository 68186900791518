import {
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalSubmitButton,
} from 'molecules/Modal/Modal.styles';
import Modal from 'molecules/Modal';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { StyledCellText } from 'molecules/Table/styles/Text.styles';
import dayjs from 'dayjs';
import { getPaymentIcon } from 'organisms/PaymentOptionCard/helpers';
import {
  StyledCellContainer,
  StyledTableContainer,
} from 'molecules/Table/styles';
import { Radio } from '@mui/material';
import Table from 'molecules/Table';
import { neutrals } from 'utils/styles/color';

const SelectAutoPaymentMethod: FC<{
  onClose: () => void;
  onSubmit: (p: PMethod) => void;
  paymentMethods: PMethod[];
  defaultMethod?: PMethod | null;
}> = ({ onClose, onSubmit, paymentMethods, defaultMethod }) => {
  const [selected, setSelected] = useState<PMethod | null>(null);

  const columns = useMemo(
    () => [
      {
        key: 'action',
        label: '',
        sortable: false,
        width: '5%',
        render: (method: PMethod) => (
          <StyledCellContainer>
            <Radio
              disabled={!method?.isVerified}
              checked={selected?.id === method.id}
              onChange={() => setSelected(method)}
              sx={{
                color: neutrals[500],
              }}
            />
          </StyledCellContainer>
        ),
      },
      {
        key: 'createdAt',
        label: 'Date added',
        sortable: false,
        width: '15%',
        render: ({ createdAt }: PMethod) => (
          <StyledCellText>
            {dayjs(dayjs(new Date(createdAt))).format('MMM DD, YYYY')}
          </StyledCellText>
        ),
      },
      {
        key: 'credit_card_info',
        label: 'Type',
        sortable: false,
        width: '10%',
        render: (method: PMethod) => {
          const { image } = getPaymentIcon(method);
          return <StyledCellContainer>{image}</StyledCellContainer>;
        },
      },
      {
        key: 'payment_method',
        label: 'Details',
        sortable: false,
        width: '70%',
        render: (method: PMethod) => {
          const { name, last4, expire } = getPaymentIcon(method);
          return (
            <StyledCellContainer>
              <div className="payment-option-info">
                <StyledCellText>
                  {name}{' '}
                  {expire !== '' ? (
                    <span>&bull;&bull;&bull;&bull; {last4}</span>
                  ) : (
                    ''
                  )}
                </StyledCellText>
                <StyledCellText sx={{ fontSize: '11px !important' }}>
                  {expire !== '' ? (
                    expire
                  ) : (
                    <span>&bull;&bull;&bull;&bull; {last4}</span>
                  )}
                </StyledCellText>
              </div>
            </StyledCellContainer>
          );
        },
      },
    ],
    [selected]
  );

  const handleRowClick = useCallback((e, r: PMethod) => {
    setSelected(r);
  }, []);

  useEffect(() => {
    const selectedPaymentMethod =
      paymentMethods.find(
        (paymentMethod) => paymentMethod.id === defaultMethod?.id
      ) || null;

    setSelected(selectedPaymentMethod);
  }, []);

  return (
    <Modal
      open
      maxWidth="md"
      title="Select auto payment method"
      subtitle="Select a payment method to be used for automatically processing invoice payments on their due date."
      onClose={onClose}
    >
      <>
        <ModalContent>
          <StyledTableContainer $new sx={{ position: 'relative' }}>
            <Table
              idKey="id"
              columns={columns}
              data={paymentMethods || []}
              onRowClick={handleRowClick}
            />
          </StyledTableContainer>
        </ModalContent>
        <ModalActions>
          <ModalCloseButton
            autoFocus
            size="medium"
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </ModalCloseButton>
          <ModalSubmitButton
            size="medium"
            variant="contained"
            onClick={() => (selected ? onSubmit(selected) : null)}
            disabled={!selected}
          >
            Apply
          </ModalSubmitButton>
        </ModalActions>
      </>
    </Modal>
  );
};

export default SelectAutoPaymentMethod;
