import React from 'react';

import { Link as RouterLink, LinkProps } from 'react-router-dom';
import styled from 'styled-components';

interface StyledLinkProps extends LinkProps {
  to: string;
  $fontSize?: string;
  $fontWeight?: number;
  $lineHeight?: string;
  $color?: string;
  $textDecoration?: 'underline' | 'none';
  children: JSX.Element[] | JSX.Element | string;
}

const StyledLink = styled(RouterLink)<StyledLinkProps>`
  && {
    overflow: hidden;
    color: ${(props) => props.$color || props.theme.palette.primary.main};
    text-overflow: ellipsis;

    font-family: Poppins, sans-serif;
    font-size: ${(props) => props.$fontSize || '0.75rem'};
    font-weight: ${(props) => props.$fontWeight || 500};
    line-height: ${(props) => props.$lineHeight || '16px'};
    font-style: normal;
    text-decoration-line: ${({ $textDecoration }) =>
      $textDecoration === 'none' ? 'none' : 'underline'};

    width: fit-content;

    p {
      display: inherit;
    }
  }
`;

const Link = ({
  to,
  $fontSize,
  $fontWeight,
  $lineHeight,
  $color,
  children,
  ...props
}: StyledLinkProps) => (
  <StyledLink
    to={to}
    $lineHeight={$lineHeight}
    $fontSize={$fontSize}
    $fontWeight={$fontWeight}
    $color={$color}
    {...props}
  >
    {children}
  </StyledLink>
);

export default Link;
