import styled from 'styled-components';
import { Button } from '@mui/material';

export const StyledPayButton = styled(Button)`
  && {
    font-family: Poppins, sans-serif;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    text-transform: uppercase;
    &:hover {
      background-color: transparent;
      text-decoration-line: underline;
    }
  }
`;
