import styled from 'styled-components';
import { Stack, Grid, Button } from '@mui/material';
import { palette } from 'utils/styles/variables';
import { neutrals } from 'utils/styles/color';

export const StyledInvoiceDetailsTitle = styled(Stack)`
  && {
    padding: 24px 16px;
    position: sticky;
    top: 76px;
    background: #fff;
    z-index: 1;
  }
`;

export const StyledTitle = styled(Stack)`
  && {
    justify-content: space-between;
    align-items: center;
    padding-bottom: 18px;

    && button {
      display: flex;
      align-items: center;
      gap: 5px;

      color: ${palette.primary.main};
      font-size: 0.875rem;
      font-weight: 600;
      text-decoration: underline;

      text-align: right;
      font-family: Poppins, sans-serif;
      font-style: normal;
      line-height: 22px;
    }
  }
`;

export const StyledActionStack = styled(Stack)`
  && {
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;

    .MuiLoadingButton-root {
      padding: 0;
      min-width: auto;
    }
  }
`;

export const StyledGridItem = styled(Grid)<{
  $bold?: boolean;
}>`
  && {
    padding-top: 4px !important;
    margin: auto 0;

    text-align: right;
    color: ${neutrals[700]};

    font-weight: 400;

    font-family: 'Poppins', sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    line-height: 16px;
    text-transform: capitalize;

    ${({ $bold }) => $bold && 'font-weight: 500 !important;'}
    ${({ $bold }) => $bold && 'font-size: 0.875rem !important;'}
    ${({ $bold }) => $bold && 'line-height: 18px !important;'}
  }
`;

export const StyledPayButton = styled(Button)`
  && {
    display: flex;
    height: 32px;
    width: 158px;
    min-width: 104px;
    padding: 2px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    color: #fff;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
`;
