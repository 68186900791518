import React from 'react';
import styled from 'styled-components';
import { CardHeader, CardContent, CardActions, Button } from '@mui/material';
import { neutrals } from 'utils/styles/color';
import { TabList } from '@mui/lab';
import { palette } from 'utils/styles/variables';

const StyledCardHeader = styled(({ ...props }) => <CardHeader {...props} />)`
  && {
    padding: 16px;

    .MuiCardHeader-avatar {
      margin-right: 12px;
    }

    .MuiCardHeader-content {
      .MuiTypography-root {
        color: ${neutrals[900]};

        font-family: Poppins, sans-serif;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
      }
    }
    .MuiCardHeader-action {
      padding: 0 !important;
      margin: 0 !important;
      align-self: center !important;
    }
  }
`;

const StyledCardContent = styled(CardContent)`
  && {
    padding: 0 16px !important;
  }
`;

const StyledTabList = styled(TabList)`
  && {
    min-height: 22px;
    .MuiButtonBase-root {
      display: flex;
      padding-top: 20px;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      flex: 1 0 0;
      min-height: 22px;

      color: ${palette.primary.main};

      font-family: Poppins, sans-serif;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      text-transform: capitalize;

      border-bottom: 1px solid ${neutrals[300]};
    }
    .Mui-selected {
      color: ${neutrals[700]};
      font-size: 1.125rem;
      font-weight: 500;
    }
    .MuiTabs-indicator {
      height: 3px;
      background-color: #fc3636;
    }
  }
`;

const StyledCardActions = styled(CardActions)`
  && {
    display: flex;
    height: 59px;
    padding: 8px 12px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }
`;

const StyledCardCancelAction = styled(Button)`
  && {
    display: flex;
    height: 32px;
    padding: 4px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-radius: 3px;
    border: 1px solid ${palette.primary.main};
    background: #fff;

    color: ${palette.primary.main};
    text-align: justify;

    font-family: Poppins, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
  }
`;

const StyledCardSubmitAction = styled(Button)`
  && {
    display: flex;
    height: 32px;
    min-width: 104px;
    padding: 4px 24px;
    margin: 0 !important;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-radius: 3px;
    background: ${palette.primary.main};

    color: #fff;
    text-align: center;

    font-family: Poppins, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
  }
`;

export {
  StyledCardHeader,
  StyledCardContent,
  StyledTabList,
  StyledCardActions,
  StyledCardCancelAction,
  StyledCardSubmitAction,
};
