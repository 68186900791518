import React, { FC, useState, useCallback } from 'react';

import Table from 'molecules/Table';
import PaginationActions from 'molecules/Table/TablePagination/Actions';
import Link from 'atoms/Link/Link';

import {
  StyledCellContainer,
  StyledTableContainer,
} from 'molecules/Table/styles';
import { StyledTablePagination } from 'molecules/Table/styles/Pagination.styles';
import { StyledCellText } from 'molecules/Table/styles/Text.styles';

import { snakeCase } from 'lodash-es';

import { ReactComponent as SettingsIcon } from 'assets/icons/edit-settings.svg';

import { Order } from 'organisms/InvoicesTable/types';
import { CloudOpsProjectsType } from 'pages/CompanyPage/types';
import { neutrals } from 'utils/styles/color';
import { palette } from 'utils/styles/variables';

export const initialValue = { page: 1, limit: 20 };

const ProjectsTable: FC<{
  loading?: boolean;
  data: { projects: CloudOpsProjectsType[]; count: number };
  onChange: (f: typeof initialValue, s?: string) => void;
  onSettings: (s: CloudOpsProjectsType) => void;
}> = ({
  loading,
  data: { projects = [], count = 0 },
  onChange,
  onSettings,
}) => {
  const [pagination, setPagination] = useState(initialValue);

  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<string>('created_at');

  const handleRequestSort = useCallback(
    async (event: React.MouseEvent<unknown>, property: 'created_at') => {
      const isAsc = orderBy === property && order === 'asc';
      const nextState = { ...pagination, page: 1 };

      onChange(
        nextState,
        `sort[${snakeCase(property)}]=${isAsc ? 'desc' : 'asc'}`
      );

      setPagination(nextState);
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [pagination, orderBy, order, onChange]
  );

  const handleChangePage = useCallback(
    async (event: unknown, newPage: number) => {
      const nextState = { ...pagination, page: newPage + 1 };
      const sort =
        order && orderBy ? `sort[${snakeCase(orderBy)}]=${order}` : '';

      onChange(nextState, sort);
      setPagination(nextState);
    },
    [pagination, orderBy, order, onChange]
  );

  const handleChangeRowsPerPage = useCallback(
    async (option: number) => {
      const nextState = { limit: option, page: 1 };
      const sort =
        order && orderBy ? `sort[${snakeCase(orderBy)}]=${order}` : '';

      onChange(nextState, sort);
      setPagination(nextState);
    },
    [orderBy, order, pagination, onChange]
  );

  const columns = [
    {
      key: 'name',
      label: 'Project name',
      sortable: true,
      width: '20%',
      render: ({ name }: CloudOpsProjectsType) => (
        <StyledCellText>{name}</StyledCellText>
      ),
    },
    {
      key: 'company_name',
      label: 'Company',
      sortable: true,
      width: '20%',
      render: ({ company_name, company_hs_id }: CloudOpsProjectsType) => (
        <Link to={`/company/${company_hs_id}`} $textDecoration="none">
          <StyledCellText $textType="link">{company_name}</StyledCellText>
        </Link>
      ),
    },
    {
      key: 'type',
      label: 'Type',
      sortable: true,
      width: '25%',
      render: (project: CloudOpsProjectsType) => (
        <StyledCellText>{project?.customType || ''}</StyledCellText>
      ),
    },
    {
      key: 'status',
      label: 'Status',
      sortable: true,
      width: '20%',
      render: ({ status }: CloudOpsProjectsType) => {
        const statusColor =
          status === 'active' ? neutrals[700] : palette.error[700]; // '#F48319';

        return (
          <StyledCellText>
            <span style={{ color: statusColor }}>
              {status === 'active' ? 'Active' : 'Deactivated'}
            </span>
          </StyledCellText>
        );
      },
    },
    {
      key: 'actions',
      label: '',
      sortable: false,
      width: '15%',
      render: (project: CloudOpsProjectsType) => (
        <StyledCellContainer $right $hStack>
          <SettingsIcon
            onClick={() => onSettings(project)}
            style={{
              color: '#6F9FE9',
              position: 'relative',
              cursor: 'pointer',
            }}
          />
        </StyledCellContainer>
      ),
    },
  ];

  return (
    <StyledTableContainer>
      <Table
        data={projects}
        columns={columns}
        idKey="id"
        sx={{ minWidth: 650 }}
        aria-label="simple table"
        loading={loading}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
      />
      <StyledTablePagination
        labelDisplayedRows={() => null}
        rowsPerPageOptions={[]}
        count={count}
        rowsPerPage={pagination.limit}
        page={count ? pagination.page - 1 : 0}
        onPageChange={handleChangePage}
        ActionsComponent={(props) => (
          <PaginationActions
            {...props}
            labelRowsPerPage="Items per page"
            rowsPerPageOptions={[20, 50, 100]}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      />
    </StyledTableContainer>
  );
};

export default ProjectsTable;
