/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// @typescript-eslint/no-explicit-any
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-expressions */
import React, { ReactElement } from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ThemeProvider } from 'styled-components';
import { palette } from 'utils/styles/variables';
import { light, neutrals } from 'utils/styles/color';

const theme = createTheme({
  palette: {
    ...palette,
  },
  typography: {
    allVariants: {
      letterSpacing: 0,
    },
    h1: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 700,
      color: '#7B828C',
      fontSize: '40px',
      lineHeight: '50px',
      marginBottom: '5px !important',
    },
    h2: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 700,
      color: '#2B2B2B',
      fontSize: '1.875rem',
      lineHeight: '48px',
    },
    h3: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 700,
      color: '#2B2B2B',
      fontSize: '1.125rem',
      lineHeight: '24px',
    },
    body1: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 500,
      color: '#2B2B2B',
      fontSize: '1rem',
      lineHeight: '16px',
    },
    body2: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 400,
      color: '#666666',
      fontSize: '0.875rem',
      lineHeight: '24px',
    },
  },
  components: {
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          color: '#6F9FE9',
          '&.Mui-checked': {
            color: '#6F9FE9',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 400,
          color: '#999999',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          // '&:hover fieldset': {
          //   borderColor: '#6F9FE9 !important',
          // },
        },
        input: {
          fontWeight: 400,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '0.75rem',
          color: '#565148',
          fontWeight: 400,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontFamily: 'Poppins, sans-serif',
          textTransform: 'none',
        },
        contained: {
          color: '#FFFFFF',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: '0.875rem',
          '&:hover': {
            backgroundColor: light[100],
          },
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: '0.875rem',
        },
        primary: {
          fontWeight: 400,
          fontSize: '0.875rem',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        title: {
          color: neutrals[900],
          fontFamily: 'Poppins, sans-serif',
          fontSize: '1.125rem',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '22px',
        },
        subheader: {
          fontSize: '0.75rem',
          fontWeight: 'normal',
          color: '#B0B6B6',
          marginTop: 8,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: '#7B828C',
          fontSize: '0.75rem',
          fontWeight: 500,
          whiteSpace: 'pre',
        },
        body: {
          color: '#565148',
          fontSize: '0.875rem',
          whiteSpace: 'pre',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: '#ccc',
        },
        colorPrimary: {
          '&.Mui-checked': {
            // Controls checked color for the thumb
            color: '#6F9FE9',
          },
        },
        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.2,
          backgroundColor: 'grey',
          '.Mui-checked.Mui-checked + &': {
            // Controls checked color for the track
            opacity: 0.7,
            backgroundColor: '#6F9FE9',
          },
        },
      },
    },
  },
});

type ThemeProps = {
  children: ReactElement | ReactElement[] | any;
};

const Theme = ({ children }: ThemeProps) => (
  <MuiThemeProvider theme={theme}>
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </MuiThemeProvider>
);

export default Theme;
