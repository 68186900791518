import React, { ChangeEvent, useRef, useState } from 'react';
import {
  Box,
  ClickAwayListener,
  Grow,
  MenuList,
  Pagination,
  Paper,
  Popper,
  Stack,
} from '@mui/material';
import { neutrals } from 'utils/styles/color';
import { ITablePaginationActionsProps } from 'molecules/Table/types';

import {
  StyledItemText,
  StyledMenuItem,
} from 'molecules/ButtonMenu/ButtonMenu.styles';
import {
  StyledLabelPerPage,
  StyledSelectPerPage,
  StyledStackPerPage,
} from 'molecules/Table/styles/Pagination.styles';

const TablePaginationActions = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
  labelRowsPerPage,
  rowsPerPageOptions,
  onRowsPerPageChange,
}: ITablePaginationActionsProps) => {
  const anchorRef = useRef<HTMLSelectElement>(null);

  const [open, setOpen] = useState(false);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    onPageChange(event, value - 1);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleItemClick = (option: number) => {
    if (onRowsPerPageChange) onRowsPerPageChange(option);
    setOpen(false);
  };

  return (
    <Stack
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <StyledStackPerPage>
        <StyledLabelPerPage>{labelRowsPerPage}</StyledLabelPerPage>
        <Box ref={anchorRef} sx={{ height: '21px' }}>
          <StyledSelectPerPage
            select
            size="small"
            value={rowsPerPage}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleItemClick(Number(e.target.value))
            }
            SelectProps={{
              open: false,
              onOpen: () => setOpen(true),
            }}
          >
            {rowsPerPageOptions?.map((option) => (
              <StyledMenuItem key={option} value={option}>
                {option}
              </StyledMenuItem>
            ))}
          </StyledSelectPerPage>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            style={{
              zIndex: 1,
              top: '10px',
            }}
            role={undefined}
            popperOptions={{
              placement: 'bottom-end',
            }}
            transition
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      sx={{ py: '4px' }}
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                    >
                      {rowsPerPageOptions?.map((option) => (
                        <StyledMenuItem
                          key={option}
                          onClick={() => handleItemClick(option)}
                        >
                          <StyledItemText>{option}</StyledItemText>
                        </StyledMenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      </StyledStackPerPage>

      <Box sx={{ display: 'flex' }}>
        <Pagination
          count={Math.max(0, Math.ceil(count / rowsPerPage))}
          page={page + 1}
          onChange={handleChange}
          size="large"
          showFirstButton
          showLastButton
          sx={{
            '.MuiButtonBase-root.MuiPaginationItem-root': {
              color: neutrals[700],
              fontFamily: 'Poppins, sans-serif',
              fontSize: '13px',
              fontWeight: 400,
              lineHeight: '18px',
            },
          }}
        />
      </Box>
    </Stack>
  );
};

export default TablePaginationActions;
