import styled from 'styled-components';
import { Box } from '@mui/material';
import { neutrals } from 'utils/styles/color';

export const Wrapper = styled.div`
  height: 100%;

  .MuiPaper-root {
    width: 100%;
    height: 100%;
    padding: 0;
    box-sizing: border-box;
  }

  .MuiTabPanel-root {
    padding: 24px 8px 0 8px;
    height: calc(100% - 80px);
  }
`;

export const MainBlock = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
    gap: 8px;

    height: 100%;
    overflow: hidden auto;
  }
`;

export const NotificationRow = styled(Box)`
  && {
    display: flex;
    align-items: center;
    gap: 16px;

    padding: 4px 8px;

    .MuiTypography-caption {
      display: block;
      width: 80px;
      min-width: 80px;

      color: ${neutrals[700]};
      font-family: Poppins, sans-serif;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 300;
      line-height: 15.5px;
    }

    .MuiTypography-body1 {
      color: ${neutrals[700]};

      font-family: Poppins, sans-serif;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    .MuiTypography-body2 {
      color: ${neutrals[500]};
      font-family: Poppins, sans-serif;
      font-size: 11px;
      font-style: normal;
      font-weight: 300;
      line-height: 16px;
    }

    .MuiBox-root {
      display: flex;
      flex-direction: column;
    }
  }

  width: 100%;
  border-bottom: 1px solid #ecedef;
`;
