import React from 'react';
import dayjs from 'dayjs';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { ReactComponent as BankCardIcon } from 'assets/icons/bank-card.svg';
import { ReactComponent as SepaCardIcon } from 'assets/icons/sepa-card.svg';
// import { ReactComponent as MasterCard } from 'assets/icons/mastercard-card.svg';
// import { ReactComponent as Visa } from 'assets/icons/visa-card.svg';
// import { ReactComponent as UnionPay } from 'assets/icons/unionpay-card.svg';
// import { ReactComponent as Amex } from 'assets/icons/amex-card.svg';
import { Typography } from '@mui/material';
import { Wrapper } from './PaymentMethod.styles';
import { neutrals } from 'utils/styles/color';
import { useWindowDimensions } from 'pages/AuthPage/AuthPage';

type PaymentMethodProps = {
  method: PMethod;
  hideExpireDate?: boolean;
};

const PaymentMethod = ({ method, hideExpireDate }: PaymentMethodProps) => {
  const { width } = useWindowDimensions();

  let name = '';
  let last4 = '';
  let image = <></>;
  let expire = '';

  if (method.paymentMethod === 'card') {
    name = method.creditCardInfo.brand;
    last4 = method.creditCardInfo.last4;
    expire = `Expires ${dayjs(
      `${method.creditCardInfo.expire_year}-${method.creditCardInfo.expire_month}`
    ).format('MMM YYYY')}`;
    image = <BankCardIcon />;
    // if (method.creditCardInfo.brand === 'visa') {
    //   image = <Visa />;
    // } else if (method.creditCardInfo.brand === 'mastercard') {
    //   image = <MasterCard />;
    // } else if (method.creditCardInfo.brand === 'amex') {
    //   image = <Amex />;
    // } else if (method.creditCardInfo.brand === 'unionpay') {
    //   image = <UnionPay />;
    // }
  } else if (method.paymentMethod === 'ach_debit') {
    name = method.banckAccountInfo.bank_name;
    last4 = method.banckAccountInfo.last4;
    image = <AccountBalanceIcon sx={{ color: neutrals[700] }} />;
  } else if (method.paymentMethod === 'acss_debit') {
    name = method.banckAccountInfo.bank_name;
    last4 = method.banckAccountInfo.last4;
    image = <AccountBalanceIcon sx={{ color: neutrals[700] }} />;
  } else if (method.paymentMethod === 'sepa_debit') {
    name = 'SEPA';
    last4 = method.banckAccountInfo.last4;
    image = <SepaCardIcon />;
  }

  return (
    <Wrapper>
      {image}
      <div className="payment-option-info">
        <Typography
          variant="body1"
          fontSize="13px"
          fontWeight={400}
          color={neutrals[700]}
        >
          {name} {expire ? <>&bull;&bull;&bull;&bull; {last4}</> : ''}
        </Typography>
        {hideExpireDate ? null : (
          <>
            {expire ? (
              <Typography
                fontSize="11px"
                lineHeight="16px"
                variant="body2"
                fontWeight={width < 600 ? 400 : 300}
                color={neutrals[700]}
              >
                {expire}
              </Typography>
            ) : (
              <Typography
                variant="body2"
                lineHeight="16px"
                fontSize="11px"
                fontWeight={width < 600 ? 400 : 300}
                color={neutrals[700]}
              >
                &bull;&bull;&bull;&bull; {last4}
              </Typography>
            )}
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default PaymentMethod;
