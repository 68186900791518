import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { camelCase, mapKeys } from 'lodash-es';
import {
  Card,
  CardHeader,
  CardContent,
  Stack,
  IconButton,
  Box,
  Alert,
} from '@mui/material';
import CardAvatar from 'atoms/CardAvatar/CardAvatar';
import InvoicesTable from 'organisms/InvoicesTable/InvoicesTable';
import { useBaoSelector } from 'utils/hooks/redux';
import { SUPER_ADMIN } from 'utils/constants/roles';
import { useNavigate } from 'react-router-dom';
import {
  HeaderTitleWrapper,
  InvoicesCircle,
  StyledHrLine,
  StyledOpenInvoicesHeaderBlock,
  StyledOpenInvoicesHeaderText,
  // StyledInvoicePaymentItem,
  TableWrapper,
} from './OpenInvoices.styles';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import { currencyIcon } from 'utils/constants/invoiceStatus';
import { currencyFormatter } from 'utils/helpers/currencyFormatter';
import { neutrals } from 'utils/styles/color';
import { palette } from 'utils/styles/variables';

const OpenInvoices = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [height, setHeight] = useState<boolean>(false);
  const [isPayed, setPayed] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<PMethod>();
  const [currency, setCurrency] = useState<
    keyof typeof currencyIcon | undefined
  >();

  const { value: user } = useBaoSelector((state) => state.user);
  const { currentCompany } = useBaoSelector((state) => state.common);
  const invoices = useBaoSelector((state) => state.invoices);

  useEffect(() => {
    if (!currentCompany) return;

    axios
      .get(
        `${process.env.REACT_APP_USER_SERVICE}/company/${currentCompany.company_hs_id}`
      )
      .then((response) => {
        const { data } = response;
        setCurrency(data.currency as keyof typeof currencyIcon);
      });
    axios
      .get(
        `${process.env.REACT_APP_BILLING_SERVICE}/payment/payment-settings`,
        { params: { company_hs_id: currentCompany.company_hs_id } }
      )
      .then(({ data }) => setPayed(data.is_auto_payment));
    axios
      .get(
        `${process.env.REACT_APP_BILLING_SERVICE}/payment/main-payment-method`,
        { params: { company_hs_id: currentCompany.company_hs_id } }
      )
      .then((res) => {
        const result = mapKeys(res.data, (v, k) => camelCase(k)) as PMethod;
        setPaymentMethod(result);
      });
  }, []);

  function getPaymentMethodName(method?: PMethod) {
    if (method?.paymentMethod === 'card') return method.creditCardInfo.brand;
    if (method?.paymentMethod === 'ach_debit')
      return method.banckAccountInfo.bank_name;
    if (method?.paymentMethod === 'acss_debit')
      return method.banckAccountInfo.bank_name;
    if (method?.paymentMethod === 'sepa_debit') return 'SEPA';
    return '';
  }
  function getPaymentMethodLast4(method?: PMethod) {
    if (method?.paymentMethod === 'card') return method.creditCardInfo.last4;
    if (method?.paymentMethod === 'ach_debit')
      return method.banckAccountInfo.last4;
    if (method?.paymentMethod === 'acss_debit')
      return method.banckAccountInfo.last4;
    if (method?.paymentMethod === 'sepa_debit')
      return method.banckAccountInfo.last4;
    return '';
  }

  return (
    <Card>
      {error && (
        <Alert severity="error" sx={{ mb: 1 }}>
          {error}
        </Alert>
      )}
      <CardHeader
        title={
          <HeaderTitleWrapper>
            <Box display="flex" alignItems="center">
              <Box component="span" mr={1}>
                Outstanding Invoices
              </Box>
              <InvoicesCircle $size={24}>{invoices.totalCount}</InvoicesCircle>
            </Box>
            <Stack direction="row">
              <StyledOpenInvoicesHeaderBlock $right>
                <StyledOpenInvoicesHeaderText>
                  Total due:
                </StyledOpenInvoicesHeaderText>

                <StyledHrLine />

                <StyledOpenInvoicesHeaderText>
                  Auto payment:
                </StyledOpenInvoicesHeaderText>
              </StyledOpenInvoicesHeaderBlock>
              <StyledOpenInvoicesHeaderBlock>
                <StyledOpenInvoicesHeaderText $pl="16px">
                  {currencyFormatter(invoices.allOpenSum, currency || 'USD')}
                </StyledOpenInvoicesHeaderText>

                <StyledHrLine />

                <Box
                  sx={{
                    minWidth: '100px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingLeft: '16px',
                    gap: '16px',
                  }}
                >
                  <StyledOpenInvoicesHeaderText variant="caption">
                    <StyledOpenInvoicesHeaderText
                      variant="caption"
                      sx={{
                        color: `${
                          isPayed ? neutrals[700] : palette.warning[600]
                        } !important`,
                      }}
                    >
                      {isPayed ? 'Enabled' : 'Turned off'}
                    </StyledOpenInvoicesHeaderText>
                    {isPayed ? (
                      <StyledOpenInvoicesHeaderText
                        variant="caption"
                        sx={{ textTransform: 'capitalize' }}
                      >
                        , {getPaymentMethodName(paymentMethod)} ****
                        {getPaymentMethodLast4(paymentMethod)}
                      </StyledOpenInvoicesHeaderText>
                    ) : (
                      ''
                    )}
                  </StyledOpenInvoicesHeaderText>
                  <IconButton
                    onClick={() => navigate('/payment')}
                    sx={{ p: 0 }}
                  >
                    <SettingsIcon />
                  </IconButton>
                </Box>
              </StyledOpenInvoicesHeaderBlock>
            </Stack>
          </HeaderTitleWrapper>
        }
        avatar={<CardAvatar />}
      />
      <CardContent sx={{ p: '0 !important' }}>
        <TableWrapper height={height}>
          <InvoicesTable
            customer
            setHeight={(e) => setHeight(e)}
            status="open"
            onError={setError}
            companyColumn={user.userType === SUPER_ADMIN}
            company={
              currentCompany?.company_hs_id
                ? String(currentCompany.company_hs_id)
                : null
            }
          />
        </TableWrapper>
      </CardContent>
    </Card>
  );
};

export default OpenInvoices;
