/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Modal, Typography } from '@mui/material';
import axios from 'axios';
import CardAvatar from 'atoms/CardAvatar/CardAvatar';
import { useLocation } from 'react-router-dom';
import DashboardTemplate from 'templates/DashboardTemplate/DashboardTemplate';
import AddPaymentMethodDialog from 'organisms/AddPaymentMethodDialog/AddPaymentMethodDialog';
import { useBaoSelector, useBaoDispatch } from 'utils/hooks/redux';
import dayjs from 'dayjs';
import { setStatusFilter } from 'store/invoices/invoicesSlice';
import {
  CustomModal,
  StyledNotificationContent,
  StyledNotificationItem,
} from './DashboardPage.styles';
import InvoiceChart from '../../organisms/InvoiceChart/InvoiceChart';
import OpenInvoices from '../../organisms/OpenInvoices/OpenInvoices';
import { useWindowDimensions } from 'pages/AuthPage/AuthPage';
import { MobileInvoiceList } from 'molecules/MobileInvoiceList/MobileInvoiceList';
import { ADMIN, SUPER_ADMIN } from 'utils/constants/roles';
import MobileDashboardTemplate from 'templates/DashboardTemplate/MobileDashboardTemplate';
import { notificationsListType } from 'organisms/CompanyNotificationsActivityCard/CompanyNotificationsActivityCard';
import { camelCase, mapKeys } from 'lodash-es';
import { getBrowserConfig, log, httpLogger } from 'utils/helpers/logger';

const DashboardPage = () => {
  const [addingPaymentMethod, setAddingPaymentMethod] = useState(false);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState<{
    count: number;
    list: notificationsListType;
  }>({
    count: 0,
    list: [
      {
        id: '',
        subject: '',
        receiver: { name: '', surname: '', email: '' },
        receivers: [],
        createdAt: '',
      },
    ],
  });

  const { width } = useWindowDimensions();

  const searchParams = new URLSearchParams(useLocation().search);
  const message = searchParams.get('payment') || '';

  const dispatch = useBaoDispatch();

  const handleClose = () => setOpen(false);

  const user = useBaoSelector((state) => state.user.value);
  const { currentCompany } = useBaoSelector((state) => state.common);
  const isNotAdmin = user.userType !== SUPER_ADMIN && user.userType !== ADMIN;

  useEffect(() => {
    dispatch(setStatusFilter('open'));
    try {
      httpLogger([getBrowserConfig()]);
    } catch (e) {
      log(e);
    }

    if (!currentCompany) return;

    axios
      .get(`${process.env.REACT_APP_NOTIFICATION_SERVICE}/emails`, {
        params: {
          company_hs_id: currentCompany?.company_hs_id,
          limit: 10,
          page: 1,
        },
      })
      .then(({ data }) => {
        setNotifications({
          count: data.count,
          list: data.emails.map((notification: notificationsListType[0]) =>
            mapKeys(notification, (v, k) => camelCase(k))
          ),
        });
      });
  }, [currentCompany, dispatch]);

  useEffect(() => {
    if (message === 'cancelled' || message === 'succeeded') {
      const url = window.location.href.split('?')[0];
      window.history.pushState({}, document.title, url);
      setOpen(true);
      localStorage.removeItem('pay');
      setTimeout(() => {
        setOpen(false);
      }, 5000);
    }
  }, [message]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  if (width < 600 && isNotAdmin) {
    return (
      <MobileDashboardTemplate>
        <MobileInvoiceList
          status="customer"
          company={currentCompany ? String(currentCompany.company_hs_id) : null}
        />
      </MobileDashboardTemplate>
    );
  }

  return (
    <DashboardTemplate
      firstColumn={
        <InvoiceChart
          companyHsId={
            currentCompany ? String(currentCompany.company_hs_id) : null
          }
          isDashboard
        />
      }
      secondColumn={
        <>
          <Card sx={{ height: 300, padding: '0 12px' }}>
            <CardHeader
              sx={{ padding: '16px 0' }}
              title="Notifications"
              avatar={<CardAvatar />}
            />
            <StyledNotificationContent>
              {notifications.list.map(({ createdAt, subject }) => (
                <StyledNotificationItem>
                  <span>
                    {createdAt ? dayjs(createdAt).format('MMM DD, YYYY') : ''}
                  </span>
                  <p>{subject}</p>
                </StyledNotificationItem>
              ))}
            </StyledNotificationContent>
          </Card>
          <AddPaymentMethodDialog
            addingPaymentMethod={addingPaymentMethod}
            onAddingPaymentMethod={setAddingPaymentMethod}
          />

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <CustomModal>
              {message === 'cancelled' && (
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ mb: 2 }}
                >
                  Error
                </Typography>
              )}
              <Typography id="modal-modal-description">
                {message === 'cancelled'
                  ? 'Payment was unsuccessful'
                  : 'Payment is in progress'}
              </Typography>
            </CustomModal>
          </Modal>
        </>
      }
      lastRow={<OpenInvoices />}
    />
  );
};

export default DashboardPage;
